/* Filename: ToggleSwitch.css */
.toggle-container {
  text-align: center;
  display: flex;
  align-items: center;
}
.toggle-switch {
  position: relative;
  width: 60px;
  display: inline-block;
  text-align: left;
  margin-left: 0.75rem;
  top: 0px;
}
.toggle-switch-box {
  position: relative;
  width: 56px;
  display: inline-block;
  text-align: left;
  top: 0px;
}
.toggle-checkbox {
  display: none;
}
.toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 16px;
}
.toggle-label-box {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 2px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  font-size: 12px;
  font-weight: 500;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 24px;
  padding-top: 1px;
  line-height: 24px;
  color: #fff;
  box-sizing: border-box;
}
.inner:before {
  padding-left: 11px;
  background-color: #060;
  color: #fff;
}
.inner:after {
  padding-right: 11px;
  background-color: rgb(95 135 161);
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 14px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 29px;
  border: 0 solid #bbb;
  border-radius: 24px;
  transition: all 0.3s ease-in 0s;
}
.switch-box {
  display: block;
  width: 14px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  border: 0 solid #bbb;
  border-radius: 2px;
  transition: all 0.3s ease-in 0s;
}
.toggle-checkbox:checked + .toggle-label .inner,
.toggle-checkbox:checked + .toggle-label-box .inner {
  margin-left: 0px;
}
.toggle-checkbox:checked + .toggle-label .switch,
.toggle-checkbox:checked + .toggle-label-box .switch-box {
  right: 0px;
}
.toggle-checkbox:disabled + .toggle-label-box {
  cursor: not-allowed;
}
