/* https://www.youtube.com/watch?v=bFvfqUMjvsA&t=1220s */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --body-color: #e4e9f7;
  --body-secondary-color: #e5e7eb;
  --sidebar-color: #fff;
  --sidebar-color-dark: rgb(0 38 75);
  --primary-color: rgb(59 113 202); /* #695cfe; */
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #606060;

  --trans-02: all 0.2s ease;
  --trans-03: all 0.3s ease;
  --trans-04: all 0.4s ease;
  --trans-05: all 0.5s ease;

  --width: 84px;
  --width-expanded: 200px;
}

/* === SideBar ===
     */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--width-expanded);
  padding: 10px 14px;
  background: var(--sidebar-color-dark);
  transition: var(--trans-05);
  z-index: 100;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.4);
}
.sidebar.close {
  width: var(--width);
}

/* === Reusable Code ===
     */

.sidebar li {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
}
.sidebar .text {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  transition: var(--trans-03);
  opacity: 1;
  white-space: nowrap;
}
.sidebar.close .text {
  opacity: 0;
}

/* ============ */

.sidebar header {
  position: relative;
}
.sidebar header .image-text {
  display: flex;
  align-items: center;
}
header .image-text .logo {
  top: 0;
  color: var(--primary-color-light);
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  position: absolute;
}
header .image-text .logo-text {
  font-size: 24px;
  color: var(--primary-color-light);
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 6px;
  letter-spacing: -0.025em;
}
header .image-text .project {
  margin-top: 8px;
  font-size: 13px;
  color: var(--primary-color-light);
}
.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
}
.sidebar .image-text img {
  width: 40px;
  border-radius: 4px;
}
.sidebar .toggle {
  position: absolute;
  margin-top: 6px;
  top: 50%;
  right: -24px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--sidebar-color-dark);
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
  color: var(--sidebar-color);
  background: var(--sidebar-color-dark);
  transform: translateY(-50%) rotate(180deg);
  transition: var(--trans-05);
}
.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}
.sidebar .menu {
  margin-top: 40px;
}
.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  min-width: 60px;
}
.sidebar li .icon,
.sidebar li .text {
  color: var(--primary-color-light);
  transition: var(--trans-02);
}
header .image-text .header-text {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.sidebar li .link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition-duration: 200ms;
  transition-property: all;
}
.sidebar li .link:hover .icon,
.sidebar li .link:hover .text {
  color: var(--text-color);
}
.sidebar li .link.active {
  border-bottom: 1px solid var(--primary-color-light);
  color: var(--text-color);
}
.sidebar li .link:hover,
.sidebar li .link.active:hover {
  background: var(--sidebar-color);
  border-radius: 4px;
}
.sidebar .menu-bar {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.outlet-section {
  position: relative;
  display: grid;
  align-content: space-between;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  left: var(--width-expanded);
  width: calc(100% - var(--width-expanded));
  /* background: var(--body-secondary-color); */
  transition: var(--trans-05);
  height: 100%;
  z-index: 0;
}
.sidebar.close ~ .outlet-section {
  left: var(--width);
  width: calc(100% - var(--width));
  align-content: space-between;
}
.sidemenu {
  position: fixed;
  right: 0;
  z-index: 20;
  --transform-translate-y: 50%;
  top: 50%;
}
.sidemenu > ol {
  margin-right: 0.5rem;
  margin-top: -4rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-top-width: 1px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.sidemenu > ol li {
  padding: 0.5rem;
  cursor: pointer;
  color: #9CA3AF
}
.sidemenu > ol li:hover {
  color: rgb(95 135 161);
}
