.ease_linear {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 150ms;
  transition-timing-function: linear
}

.input-trans {
  --tw-translate-x: 0rem;
  --tw-translate-y: -0.6rem;
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0px;
  padding-bottom: 0px;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
  opacity: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 150ms;
  transition-timing-function: linear
}

.float-input {
  display: flex;
  max-width: 400px
}

.float-input > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.float-input .pop-btn {
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.float-input .popover {
  position: relative;
  z-index: 10;
  display: flex;
  width: auto;
  align-items: flex-start
}

.float-input .popover > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.float-input .popover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms
}

.pbody {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between
}

.pbody .search {
  display: flex;
  align-items: center
}

.pbody .search > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.pbody .user {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center
}

.users {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.users > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-divide-opacity))
}

.users {
  padding: 0px
}

.users .admins {
  display: flex;
  max-height: 24rem;
  min-height: 120px;
  width: 100%;
  justify-content: space-between
}

.users .admins > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.users .accts {
  display: flex;
  min-height: 120px;
  width: 100%;
  justify-content: space-between
}

.users .accts > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.users .accts {
  padding-top: 1.5rem
}

.users .tl {
  font-size: 14px;
  line-height: 1.25
}

.accnt {
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(95 135 161 / var(--tw-border-opacity));
  padding: 1rem
}

.accnt .controls {
  display: flex;
  width: 100%;
  flex-direction: column
}

.accnt .controls > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
}

.accnt .controls .name {
  margin-bottom: 0.5rem;
  display: flex
}

.accnt .controls .name > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.userrole,
.review {
  height: 100%
}

.userrole .title {
  text-align: center;
  line-height: 1.25;
  letter-spacing: -0.025em
}

.userrole .alert {
  display: flex;
  width: 100%;
  justify-content: center
}

.userrole .roles {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column
}

.userrole .roles > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.userrole .roles {
  padding: 0.5rem
}

.userrole .role {
  display: flex
}

.userrole .role > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.userrole .role .control {
  display: flex;
  width: 33.333333%;
  align-items: flex-start;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
  padding-left: 1.25rem;
  padding-top: 0.75rem
}

.userrole .role .details {
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.userrole .role .details.active {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.review .title {
  display: flex;
  justify-content: center;
  text-align: center
}

.review .toast {
  display: flex;
  width: 100%;
  justify-content: center
}

.policy {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 100%
}

.policy .title {
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.policy .title .role {
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity))
}

.policy .back {
  margin-top: 0.5rem;
  margin-right: 1.75rem;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end
}

.policy .back > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.policy .back {
  border-style: none;
  padding-bottom: 0.5rem
}

.policy .entry {
  display: flex;
  align-items: center
}

.policy .entry > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.policy .entry {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.policy .values {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem
}

.policy .back .icon {
  display: flex;
  align-items: center
}

.policy .back .icon > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.policy .back .icon {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity))
}

.editbtn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-radius: 0.125rem;
  border-bottom-width: 1px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.editbtn .edit {
  display: flex;
  align-items: center
}

.editbtn .edit > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.editbtn .edit {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity))
}

.flexj {
  display: flex;
  justify-content: center
}

.flexj > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
}

.stepbtn {
  align-items: center;
  display: flex;
  justify-content: center
}

.stepbtn > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
}

.stepbtn > .label {
  margin-top: 0.125rem;
  font-size: 0.75rem;
  line-height: 1rem
}

.response {
  margin: 1rem;
  margin-bottom: 0px;
  margin-top: 2.5rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 1px;
  padding: 1.5rem;
  padding-bottom: 0px;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.response > .success-icon {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 48px;
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity))
}

.response > .err-icon {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 48px;
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity))
}

.response > .err-title {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem
}

.response > .err-message {
  margin: 1.5rem;
  width: 100%;
  text-align: center
}

.response > .success {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem
}

.response .title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  font-size: 14px
}

.response .email {
  grid-column: span 2 / span 2;
  text-align: center
}

.response .pwd {
  margin-left: 1rem;
  font-size: 13px;
  --tw-text-opacity: 1;
  color: rgb(0 38 75 / var(--tw-text-opacity))
}

/* npx tailwindcss -i ./src/assets/css/users.css -o ./src/assets/css/users.main.css --watch */