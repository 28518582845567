:root {
  --color: #E5E7EB;
}
.tooltip {
  position: relative;
  z-index: 50;
}
.tooltip::before {
  content: attr(data-tip);
  position: absolute;
  /* 
  vertically centered */
  top: 50%;
  transform: translateY(-50%);
  /* 
  basic styles */
  border-radius: 4px;
  width: auto;
  height: auto;
  background: var(--color);
  color: #333;
  text-align: center;
  font-size: 13px;
  padding: 6px;
  white-space: nowrap;

  opacity: 0;
  transition: 0.3s opacity;
  
}
.tooltip.left::before {
  /* reset defaults */
  left: initial;
  margin: initial;

  /* set new values */
  right: 100%;
  margin-right: 10px;
}
.tooltip.right::before {
  right: initial;
  margin: initial;
  /* 
  move to the right */
  left: 100%;
  margin-left: 10px;
}
.tooltip.bottom::before {
  top: 100%;
  left: 50%;
  margin-top: 12px;
  transform: translateY(10%);
  transform: translateX(-50%);
}
.tooltip:hover::before,
.tooltip:hover::after {
  display: inline-flex;
  opacity: 1;
}
.tooltip::after {
  content: "";
  position: absolute;
  border: 6px solid #e5eaf5;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  opacity: 0;
  transition: 0.3s opacity;
}
.tooltip.left::after {
  right: 100%;
  margin-right: -2px;
  border-color: transparent transparent transparent #e5eaf5;
}
.tooltip.right::after {
  left: 100%;
  margin-left: -2px;
  border-color: transparent #e5eaf5 transparent transparent;
}
.tooltip.bottom::after {
  top: 88%;
  margin-top: 5px;
  transform: translateY(5%);
  transform: translateX(50%);
  border-color: transparent transparent #e5eaf5 transparent;
}
