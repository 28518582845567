  /**
   * Framework starts from here ...
   * ------------------------------
   */
  
  .tree,
  .tree ul {
    margin: 0 0 0 1em; /* indentation */
    padding: 0;
    list-style: none;
    position: relative;
    font-size: 14px;
  }
  
  .tree ul {margin-left:.5em} /* (indentation/2) */
  
  .tree:before,
  .tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid;
  }
  
  .tree li {
    margin: 0 0 1em 0;
    padding: 0.25em 1.5em; /* indentation + .5em */
    /* line-height:2em; default list item's `line-height` */
    font-weight: normal;
    position: relative;
  }
  
  .tree li:before {
    content:"";
    display:block;
    width:16px; /* same with indentation */
    height:0;
    border-top:1px solid;
    margin-top:-1px; /* border top width */
    position:absolute;
    top:1em; /* (line-height/2) */
    left:0;
  }

  .tree li .description {
    letter-spacing: -0.025em; 
    color: #374151;
  }
  
  .tree li:last-child:before {
    background:white; /* same with body background */
    height:auto;
    top:1em; /* (line-height/2) */
    bottom:0;
    border-bottom-left-radius: 0.25rem; 
  }

  .tree-label {
    font-weight: 600;
    color: #4B5563;
    font-size: 14px;
  }